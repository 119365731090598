<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2022-02-22 15:44:16
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-26 19:24:16
-->
<template>
  <div>

    <HqDrawer
      :title="row.paymentMethod == 8?'银行流水号':'第三方订单'"
      :visible.sync="drawer"
      size="90%"
      ref="drawer"
      :modal="modal"
    >
      <div class="page-width">
        <!-- 搜索表单区 -->
        <el-row :gutter="24" style="margin: 12px 0">
          <!-- 银行 -->
          <template v-if="row.paymentMethod == 8">
            <el-col :span="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="payTime"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="payTime"
                    label="支付时间"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="payTime" no-title range locale="zh-cn">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">取消</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(payTime)">
                    确认
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </el-col>
            <el-col :span="6">
              <v-text-field
                v-model="orderId"
                label="流水号"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                placeholder="流水号前6位/后6位"
              ></v-text-field>
            </el-col>
            <el-col :span="3">
              <v-text-field
                v-model="beginpayamount"
                label="支付最小金额"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-col>
            <el-col style="padding-left: 0;width: 1px;line-height: 32px">
              至
            </el-col>
            <el-col :span="3">
              <v-text-field
                v-model="endpayamount"
                label="支付最大金额"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-col>
          </template>
          <!-- 第三方 -->
          <el-col :span="6" v-else>
              <v-text-field
                v-model="orderId"
                label="订单号"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                placeholder="订单号前6位/后6位"
              ></v-text-field>
            </el-col>
          <el-col :span="4">
            <el-button style="margin-left: 12px" size="small" type="primary" @click="onSearch">查询</el-button>
          </el-col>
        </el-row>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          :header-cell-style="{ background: '#e5f2ff' }"
          header-cell-class-name="normal-table-header"
          highlight-current-row
          @current-change="onCurrentChange"
          element-loading-text="拼命加载中"
          v-loading="loadding"
          v-if="row.paymentMethod == 8" key="1"
        >
          <!-- 银行流水 -->
            <el-table-column :show-overflow-tooltip="true"
              label="银行流水号"
              prop="platformordernum"
              min-width="120"
              
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="支付日期"
              prop="paymenttime"
              min-width="120"
              
            >
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="支付金额"
              prop="paidinmoney"
              min-width="120"
              
            >
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="已用金额"
              prop="usedmoney"
              min-width="120"
              
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="可用金额"
              prop="availablemoney"
              min-width="120"
              
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="校区名称"
              prop="orgname"
              min-width="120"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="账户"
              prop="accnum"
              min-width="120"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="户名"
              prop="accname"
              min-width="120"
            ></el-table-column>
            <el-table-column
              label="成交订单"
              prop="collectiontime"
              min-width="120"
            >
              <template slot-scope="scope">
                <div>
                  <el-button type="text"  @click="goDetail(scope.row)" v-if="Number(scope.row.usedmoney) > 0">查看已使用订单</el-button>  
                  <span v-else>暂无</span>
                </div>
              </template>
            </el-table-column>
        </el-table>

        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          :header-cell-style="{ background: '#e5f2ff' }"
          header-cell-class-name="normal-table-header"
          highlight-current-row
          @current-change="onCurrentChange"
          element-loading-text="拼命加载中"
          v-loading="loadding"
          v-if="row.paymentMethod != 8" :key="2"
        >
          <!-- 第三方 -->
            <el-table-column :show-overflow-tooltip="true"
              label="第三方订单号"
              prop="extOrderNo"
              min-width="120"
              
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="支付时间"
              prop="extPayTime"
              min-width="120"
              
            >
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="支付金额"
              prop="moneyReceived"
              min-width="90"
              
            >
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="已用金额"
              prop="moneyUsed"
              min-width="90"
              
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="可用金额"
              prop="moneyAvailable"
              min-width="90"
              
            ></el-table-column>
            <el-table-column
              label="成交订单"
              prop="collectiontime"
              min-width="140"
            >
              <template slot-scope="scope">
                <div>
                  <el-button type="text" v-if="Number(scope.row.moneyUsed) > 0" @click="goDetail(scope.row)">查看已使用订单</el-button>  
                  <span v-else>暂无</span>
                </div>
              </template>
            </el-table-column>
        </el-table>
      </div>
      <div slot="footer">
        <el-button size="small" @click="goBack">取消</el-button>
        <el-button size="small" type="primary" @click="save">确认</el-button>
      </div>
    </HqDrawer>
    <HqModal style="z-index:99999;" :width="'826'" :visible="tearchModel" title="查看已使用订单" @on-cancel="tearchModel = false">
      <el-table
          ref="multipleTable"
          :data="tableDetail"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          :header-cell-style="{ background: '#e5f2ff' }"
          header-cell-class-name="normal-table-header"
          highlight-current-row
          @current-change="onCurrentChange"
          element-loading-text="拼命加载中"
          v-loading="loadding"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="订单号"
            prop="collectionvbillcode"
            min-width="140"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="已用金额"
            prop="damount"
            min-width="80"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="成交校区"
            prop="orgname"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="招生老师"
            prop="teachername"
            min-width="80"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="收款时间"
            prop="collectiontime"
            min-width="120"
          ></el-table-column>
        </el-table>
        <template #footer>
          <el-button
            type="primary"
            @click="tearchModel = false"
            >返回</el-button
          >
        </template>
    </HqModal>
  </div>
</template>
<script>
export default {
  props: {
    modal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      drawer: true,
      tableData: [],
      orderId: "",
      row: {},
      // 选中
      currentRow: {},
      index: null,
      loadding: false,
      tearchModel: false,
      tableDetail: [],
      menu: false,
      payTime: [],
      beginpayamount: "",
      endpayamount: "",
    }
  },
  methods: {
    init(row, index) {
      this.$refs.drawer.init()
      this.row = row
      this.index = index
      this.tableData = []
      this.orderId = ""
      if(this.row.paymentMethod == 8) {
        this.onSearch()
      }
    },
    onSearch() {
      this.loadding = true
      this.row.clearingformcode = this.row.clearingformcode
      // 银行
      if(this.row.paymentMethod == 8) {
        const params = {
          accnum: this.row.bankAccountPk,
          clearingformcode: this.row.clearingformcode,
          platformordernum: this.orderId,
          beginpaydata: this.payTime[0],
          payenddata: this.payTime[1],
          beginpayamount: this.beginpayamount,
          endpayamount: this.endpayamount,
        }
        
        this.$fetch("getThirdOrderList", params).then(res => {
          this.tableData = res.data || []
          if(!res.data.length) {
            this.$message.error('暂无相关订单记录，如有问题请联系第三方平台运营老师确认')
          }
          this.loadding = false
        })
      } else {
        // 第三方
        if(this.row.thirdType == 'nc') {
          // nc
          const params = {
            clearingformcode: this.row.clearingformcode,
            platformordernum: this.orderId,
          }
          
          this.$fetch("getThirdOrderList", params).then(res => {
            this.tableData = res.data.map(item => {
              return {
                extOrderNo: item.platformordernum,
                extPayTime: item.paymenttime,
                moneyReceived: item.paidinmoney,
                moneyUsed: item.usedmoney,
                moneyAvailable: item.availablemoney,
              }
            })
            if(!res.data.length) {
              this.$message.error('暂无相关订单记录，如有问题请联系第三方平台运营老师确认')
            }
            this.loadding = false
          })
        } else {
          // 天翼
          const params = {
            settlementId: this.row.id,
            extOrderNo: this.orderId,
            exceptionStatus: 0,
            type: 2
          }
          
          this.$fetch("getStatementFindAll", params).then(res => {
            this.tableData = res.data || []
            if(!res.data.length) {
              this.$message.error('暂无相关订单记录，如有问题请联系第三方平台运营老师确认')
            }
            this.loadding = false
          }).catch(res => {
            this.$message.error(res.msg)
            this.loadding = false
          })
        }
      }
    },
    onReset() {
      this.orderId = ""
      this.tableData = []
    },
    onCurrentChange(row) {
      this.currentRow = row
    },
    // 选中
    save() {
      let money, saleNumber, statementId;
      if(this.row.paymentMethod == 8) {
        // 银行
        money = this.currentRow.availablemoney
        saleNumber = this.currentRow.platformordernum
        if(Number(this.currentRow.availablemoney) == 0 && this.row.oldSerialNumber != saleNumber) {
          this.$message.error("该订单号可用金额为0，不可选中")
          return false
        }
      } else {
        // 第三方
        money = this.currentRow.moneyAvailable
        saleNumber = this.currentRow.extOrderNo
        statementId = this.currentRow.id
        if(Number(this.currentRow.moneyAvailable) == 0 && this.row.oldSerialNumber != saleNumber) {
          this.$message.error("该订单号可用金额为0，不可选中")
          return false
        }
      }
      
      this.$emit('thirdSelect', saleNumber, money, this.index, statementId);
      this.$refs.drawer.close()
      this.onReset()
    },
    // 返回
    goBack() {
      this.$refs.drawer.close()
      this.onReset()
    },
    async goDetail(row) {
      if(this.row.paymentMethod == 8 || this.row.thirdType == 'nc') {
        // 银行
        const res = await this.$fetch("GetUseThirdpartyOrderServlet", { clearingformcode: this.row.clearingformcode, platformordernum: this.row.thirdType == 'nc'?row.extOrderNo:row.platformordernum, accnum: this.row.bankAccountPk })
        this.tableDetail = res.data
        this.tearchModel = true
      } else {
        // 第三方
        const res = await this.$fetch("getFindStatementOrder", { statementId: row.id })
        this.tableDetail = res.data.map(item => {
          return {
            collectionvbillcode: item.orderNo,
            damount: item.moneyUsed,
            orgname: item.schoolName,
            teachername: item.teacherName,
            collectiontime: item.createTime
          }
        })
        this.tearchModel = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .page-width {
    width: 94%;
    margin: 0 auto;
    padding-bottom: 90px;
  }
  ::v-deep .confirm_wrapper {
    z-index: 999999 !important;
  }
</style>
